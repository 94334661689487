import { __assign, __extends } from "tslib";
import { Thing } from "./Thing";
var ChatMessage = /** @class */ (function (_super) {
    __extends(ChatMessage, _super);
    function ChatMessage(init) {
        var _this = _super.call(this, init) || this;
        _this.userId = init.userId;
        _this.text = init.text;
        return _this;
    }
    ChatMessage.prototype.toDynamic = function () {
        return __assign({}, this);
    };
    return ChatMessage;
}(Thing));
export { ChatMessage };
