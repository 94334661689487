import { isDev } from "./tippelaget.config";
export var BASE_URL_FUNCTIONS = isDev
    ? "http://localhost:5000/tippelaget-c02e1/europe-west1"
    : "https://europe-west1-tippelaget-c02e1.cloudfunctions.net";
// TODO: Move this to .env file
export var firebaseConfig = {
    apiKey: "AIzaSyDZ7MkKM7YqIQcwaDpAyCIrZpC1EwThugg",
    authDomain: "tippelaget-c02e1.firebaseapp.com",
    databaseURL: "https://tippelaget-c02e1.firebaseio.com",
    projectId: "tippelaget-c02e1",
    storageBucket: "tippelaget-c02e1.appspot.com",
    messagingSenderId: "545182986694",
    appId: "1:545182986694:web:00f02224f82f403c18c2c7",
    measurementId: "G-JFCG8RWY5S",
};
