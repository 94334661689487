import { __assign, __awaiter, __generator } from "tslib";
import { computed, onMounted, ref } from "vue";
import { fAuth, fDb, fAnalytics, getDocCacheFirst } from "../registerFirebase";
import { User } from "../../../shared/models/User";
import { UserTeamInfo } from "../../../shared/models/UserTeamInfo";
var isMounted = false;
var isEmailVerified = false;
var user = ref({});
var getQueryByUserId = function (uid) { return fDb.doc("users/" + uid); };
var fetchUser = function () {
    var firebaseUser = fAuth.currentUser;
    if (!firebaseUser) {
        console.warn("fetch user failed, firebaseUser null");
        return;
    }
    isEmailVerified = firebaseUser.emailVerified;
    isMounted = true;
    var query = getQueryByUserId(firebaseUser.uid);
    query.onSnapshot(function (snapshot) {
        user.value = new User(__assign(__assign({}, snapshot.data()), { id: snapshot.id }));
        console.debug("fetchUser snapshot finished", snapshot.metadata, user.value);
    }, function (error) {
        console.debug("fetchUser snapshot error", error);
        isMounted = false;
    });
};
var saveUser = function (user) { return __awaiter(void 0, void 0, void 0, function () {
    var query, couponLength, latestGameId, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                console.debug("saving user", user);
                if (!(user === null || user === void 0 ? void 0 : user.id))
                    return [2 /*return*/];
                query = getQueryByUserId(user.id);
                if (user.couponGameIds.length < user.coupons.length) {
                    couponLength = user.coupons.length;
                    latestGameId = user.coupons[couponLength - 1].gameId;
                    user.couponGameIds.push(latestGameId);
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, query.set(user.toDynamic())];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                console.debug(error_1);
                return [3 /*break*/, 4];
            case 4:
                console.debug("saved user", user);
                return [2 /*return*/];
        }
    });
}); };
var getUser = function (id, cacheFirst) {
    if (cacheFirst === void 0) { cacheFirst = true; }
    return __awaiter(void 0, void 0, void 0, function () {
        var query, doc, _a, cacheFirstUser;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    query = getQueryByUserId(id);
                    if (!cacheFirst) return [3 /*break*/, 2];
                    return [4 /*yield*/, getDocCacheFirst(query)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, query.get()];
                case 3:
                    _a = _b.sent();
                    _b.label = 4;
                case 4:
                    doc = _a;
                    if (!doc.exists) {
                        console.debug("user not found", id);
                        return [2 /*return*/];
                    }
                    cacheFirstUser = new User(__assign(__assign({}, doc.data()), { id: id }));
                    console.debug("user", cacheFirstUser, "from " + (doc.metadata.fromCache ? "cache" : "server"));
                    return [2 /*return*/, cacheFirstUser];
            }
        });
    });
};
var addTeamToUser = function (user, teamId) { return __awaiter(void 0, void 0, void 0, function () {
    var teamInfo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(user === null || user === void 0 ? void 0 : user.id))
                    return [2 /*return*/];
                if (!teamId)
                    return [2 /*return*/];
                if (user.teamInfoList.some(function (x) { return x.teamId === teamId; })) {
                    console.debug("add team failed, team in user teamInfoList");
                    return [2 /*return*/];
                }
                teamInfo = new UserTeamInfo({ teamId: teamId });
                user.teamInfoList.push(teamInfo);
                return [4 /*yield*/, saveUser(user)];
            case 1:
                _a.sent();
                console.debug("team", teamId, "added to user", user);
                return [2 /*return*/];
        }
    });
}); };
var disconnectTeamFromUser = function (user, teamId) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(user === null || user === void 0 ? void 0 : user.id))
                    return [2 /*return*/];
                if (!teamId)
                    return [2 /*return*/];
                if (!user.teamInfoList.some(function (x) { return x.teamId === teamId; })) {
                    console.debug("disconnect team failed, team not in user teamInfoList");
                    return [2 /*return*/];
                }
                user.teamInfoList = user.teamInfoList.filter(function (x) { return x.teamId !== teamId; });
                return [4 /*yield*/, saveUser(user)];
            case 1:
                _a.sent();
                console.debug("disconnected team", teamId, "from user", user.id);
                return [2 /*return*/];
        }
    });
}); };
var deleteUser = function (userId) { return __awaiter(void 0, void 0, void 0, function () {
    var query;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                query = getQueryByUserId(userId);
                return [4 /*yield*/, query.delete()];
            case 1:
                _a.sent();
                console.debug("deleted db user", userId);
                fAnalytics.logEvent("user_deleted", { userId: userId });
                return [2 /*return*/];
        }
    });
}); };
export default (function () {
    if (!isMounted) {
        onMounted(fetchUser);
    }
    var saveCurrentUser = function () { return saveUser(user.value); };
    var isAuthenticated = computed(function () { return !!user.value.id; });
    return {
        isAuthenticated: isAuthenticated,
        isEmailVerified: isEmailVerified,
        user: user,
        saveCurrentUser: saveCurrentUser,
        saveUser: saveUser,
        getUser: getUser,
        addTeamToUser: addTeamToUser,
        disconnectTeamFromUser: disconnectTeamFromUser,
        deleteUser: deleteUser,
        createRandomUser: createRandomUser,
    };
});
var createRandomUser = function (userId, email) {
    return new User({
        id: userId,
        name: email.split("@")[0],
        image: "/img/football-head.png",
        description: '',
        position: '',
    });
};
/**
 * TODO:
 * phone auth: https://firebase.google.com/docs/auth/web/phone-auth
 * fb auth: https://firebase.google.com/docs/auth/web/facebook-login
 * google auth: https://firebase.google.com/docs/auth/web/google-signin
 */
