<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>

  <!-- <TabMenu v-if="user" :tabs="[
      ...teams.map(x => {
        return {
          text: x.name,
          isActive: contextId === x.id,
          onClick: () => {
            contextId = x.id;
            lastSelectedTeamId = x.id;
          }
        };
      }),
      {
        text: 'Alle tippere',
        isActive: contextId === user.id,
        onClick: () => (contextId = user.id)
      },
      {
        text: 'Alle tippelag',
        isActive: contextId === '',
        onClick: () => (contextId = '')
      }
    ]" />

  <Spinner v-if="isLoading" />

  <div class="tab-content tab-content--wide" v-else-if="bucketsFiltered.length === 0">
    <div class="intro-wrapper">
      <p>Ingen resultater i denne perioden. Sesongstart?</p>
    </div>
  </div>
  <div class="tab-content tab-content--wide" v-else>
    <table class="result-table">
      <thead>
        <tr>
          <th class="u-text-left"></th>
          <th colspan="2">Antall rette</th>
        </tr>
        <tr>
          <th class="result-table__user u-text-left">
            <span v-if="bucketsFiltered[0].isUser">Spiller</span>
            <span v-else>Tippelag</span>
          </th>
          <th>Sist uke</th>
          <th>Totalt</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(bucket, i) in bucketsFiltered" :key="`${bucket.id} ${i}`" :class="{ me: bucket.id === user.id }">
          <td class="result-table__user">
            <span class="result-table__number">{{ i + 1 }}</span>
            <router-link :to="`/${bucket.isUser ? 'user' : 'team'}/${bucket.id}`">
              {{ bucket.name }}
            </router-link>
          </td>
          <td class="u-text-right" :key="`${bucket.id} ${i}`">
            {{ bucket.recentScore }}
          </td>
          <td class="u-text-right">{{ bucket.totalScore }}</td>
        </tr>
      </tbody>
    </table>
  </div> -->
</template>

<!-- <script lang="ts">
import { defineComponent, computed } from "vue"
import TabMenu from "@/features/Navigation/TabMenu.vue"
import userStore from "../store/userStore"
import contextStore from "../store/contextStore"
import teamStore from "../store/teamStore"
import gameStore from "../store/gameStore"
import couponStore from "../store/couponStore"
import scoreStore from "../store/scoreStore"
import { Score } from "../../../shared/models/Score"

export default defineComponent({
  setup() {
    const { isAuthenticated, user } = userStore()
    const { contextId, contextType } = contextStore()
    const { teams, lastSelectedTeamId } = teamStore()
    const { currentGame, mostRecentGameWithResults } = gameStore()
    const { currentCoupon } = couponStore()
    const { bucketResults, isLoading } = scoreStore()

    const bucketsFiltered = computed(() => {
      if (!bucketResults.value) return []

      let filtered = []

      // users
      if (contextId.value === user.value.id) {
        filtered = bucketResults.value.filter((x) => x.isUser)
      }
      // current team users
      else if (contextType.value == "TEAM" && teams.value) {
        const team = teams.value.find((x) => x.id === contextId.value)
        if (!team) return []

        filtered = bucketResults.value.filter((x) =>
          team.memberIds.includes(x.id)
        )
      }
      // all teams
      else {
        filtered = bucketResults.value.filter((x) => x.isUser === false)
      }

      if (!mostRecentGameWithResults.value) return filtered

      const recentScoreGameId = mostRecentGameWithResults.value.id

      filtered = filtered.map((x) => {
        const recentScore =
          x.scores.find((y: Score) => y.gameId === recentScoreGameId)
            ?.correctChoices || 0
        return { ...x, recentScore }
      })
      
      return filtered
    })

    return {
      isAuthenticated,
      user,
      contextId,
      teams,
      lastSelectedTeamId,
      currentGame,
      mostRecentGameWithResults,
      currentCoupon,
      bucketsFiltered,
      isLoading,
    }
  },
  components: {
    TabMenu,
  },
})
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/intro";
@import "./src/SASS/modules/result-table";
</style>
