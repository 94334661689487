import { __assign, __extends } from "tslib";
import { ChatMessage } from "./ChatMessage";
import { DeliveryCoupon } from "./DeliveryCoupon";
import { VisualThing } from "./VisualThing";
var Team = /** @class */ (function (_super) {
    __extends(Team, _super);
    function Team(init) {
        var _this = _super.call(this, init) || this;
        _this.captainUid = init.captainUid;
        _this.memberIds = init.memberIds || [];
        _this.coupons = init.coupons ? init.coupons.map(function (x) { return new DeliveryCoupon(x); }) : [];
        _this.couponGameIds = _this.coupons.map(function (x) { return x.gameId; });
        _this.chatMessages = init.chatMessages ? init.chatMessages.map(function (x) { return new ChatMessage(x); }) : [];
        _this.seasonScores = init.seasonScores || [];
        _this.hasCoupons = _this.coupons.length > 0;
        _this.hasMembers = _this.memberIds.length > 0;
        _this.hasChatMessages = _this.chatMessages.length > 0;
        _this.hasSeasonScores = _this.seasonScores.length > 0;
        if (!init.captainUid && init.memberIds && init.memberIds.length > 0)
            _this.captainUid = init.memberIds[0];
        return _this;
    }
    Team.prototype.toDynamic = function () {
        return __assign(__assign({}, this), { coupons: this.coupons.map(function (x) { return x.toDynamic(); }), chatMessages: this.chatMessages.map(function (x) { return x.toDynamic(); }) });
    };
    return Team;
}(VisualThing));
export { Team };
