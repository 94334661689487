import { defineComponent } from "vue";
import { getHumanReadableTimeSince } from "../../../shared/utils/dateHelper";
export default defineComponent({
    props: {
        pastDate: {
            type: Date,
            required: true
        }
    },
    setup: function (props) {
        var localDate = "";
        var friendlyDate = getHumanReadableTimeSince(props.pastDate);
        if (friendlyDate) {
            localDate = props.pastDate.toLocaleString("no", { hour12: false });
        }
        return { friendlyDate: friendlyDate, localDate: localDate };
    }
});
