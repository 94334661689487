import { defineComponent, ref } from "vue";
import { fAuth } from "../../registerFirebase";
import { getFirebaseErrorMessage } from "../../i18n/firebaseErrorMessages";
import userStore from "../../store/userStore";
export default defineComponent({
    setup: function () {
        var errorMessage = ref("");
        var isAuthenticated = userStore().isAuthenticated;
        var logout = function () {
            fAuth
                .signOut()
                .then(function () {
                console.debug("signed out");
            })
                .catch(function (firebaseError) {
                errorMessage.value = getFirebaseErrorMessage(firebaseError);
            })
                .finally(function () {
                window.location.replace("/login");
            });
        };
        return { isAuthenticated: isAuthenticated, logout: logout };
    },
});
