import { createRouter, createWebHistory } from "vue-router";
import { fAuth } from "../registerFirebase";
// Preloaded components
import Chat from "@/features/Chat.vue";
import Coupon from "@/features/Coupon/Coupon.vue";
import Login from "@/features/Login.vue";
import Profile from "@/features/Profile/Profile.vue";
import Table from "@/features/Table.vue";
var routes = [
    {
        path: "/",
        redirect: "/kupong",
        name: "Tippelaget",
        meta: { hidePageTitle: true },
    },
    {
        path: "/kupong",
        name: "Kupong",
        component: Coupon,
        meta: { requiresAuth: true },
    },
    {
        path: "/sign-up",
        name: "Registrer deg",
        component: function () { return import("@/features/SignUp.vue"); },
    },
    {
        path: "/login",
        name: "Logg inn",
        component: Login,
    },
    {
        path: "/forgot",
        name: "Glemt passord",
        component: function () { return import("@/features/ForgotPassword.vue"); },
    },
    {
        path: "/about",
        name: "Om Tippelaget",
        component: function () { return import("@/features/About.vue"); },
    },
    {
        path: "/sla",
        name: "Brukervilkår",
        component: function () { return import("@/features/SLA.vue"); },
    },
    {
        path: "/profile",
        name: "Profil",
        component: Profile,
        meta: { requiresAuth: true },
    },
    {
        path: "/user/:uid",
        name: "Bruker",
        component: function () { return import("@/features/Profile/ProfilePublic.vue"); },
        meta: { requiresAuth: true },
        props: true,
    },
    {
        path: "/chat",
        name: "Chat",
        component: Chat,
        meta: { requiresAuth: true },
    },
    {
        path: "/table",
        name: "Tabell",
        component: Table,
        meta: { requiresAuth: true },
    },
    {
        path: "/team/:teamId",
        name: "Tippelag",
        component: function () { return import("@/features/Team/TeamPublic.vue"); },
        meta: { requiresAuth: true },
        props: true,
    },
    {
        path: "/team-create",
        name: "Opprett tippelag",
        component: function () { return import("@/features/Team/TeamCreate.vue"); },
        meta: { requiresAuth: true },
    },
    {
        path: "/team-connect",
        name: "Koble til tippelag",
        component: function () { return import("@/features/Team/TeamConnect.vue"); },
        children: [
            {
                path: ":teamId",
                name: "Koble til tippelag",
                component: function () { return import("@/features/Team/TeamConnect.vue"); },
            },
        ],
        meta: { requiresAuth: true },
        props: true,
    },
    {
        path: "/admin",
        name: "Admin",
        component: function () { return import("@/features/Admin.vue"); },
    },
    {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: function () { return import("@/features/404.vue"); },
    },
];
var router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
});
router.beforeEach(function (to, from, next) {
    var hidePageTitle = to.matched.some(function (x) { return x.meta.hidePageTitle; });
    document.title = hidePageTitle || !to.name ? "Tippelaget.no" : String(to.name) + " - Tippelaget.no";
    var requiresAuth = to.matched.some(function (x) { return x.meta.requiresAuth; });
    if (requiresAuth && !fAuth.currentUser) {
        next({ path: "/login", query: { returnUrl: to.path } });
    }
    else {
        next();
    }
});
export default router;
