<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>

  <!-- <div>
    <TabMenu
      v-if="user"
      :tabs="[
        ...teams.map(x => {
          return {
            text: x.name,
            isActive: lastSelectedTeamId === x.id,
            onClick: () => {
              contextId = x.id;
              lastSelectedTeamId = x.id;
            }
          };
        })
      ]"
    />

    <Spinner v-if="!currentTeam || !user" />

    <div v-else class="tab-content">
      <div class="chat">
        <div class="chat-message-list" id="chatMessageList">
          <button
            class="btn btn--x-small btn--text-link"
            style="display:block; margin-left:auto; margin-bottom:1em;"
            @click="fetchOlderMessages(take + 14)"
            v-if="visibleChatMessages.length < currentTeam.chatMessages.length"
          >
            Eldre meldinger
          </button>
          <div
            v-for="chatMessage in visibleChatMessages"
            :key="chatMessage.id"
            :class="user.id === chatMessage.userId && 'chat-item-wrapper--mine'"
            class="chat-item-wrapper"
          >
            <div class="chat-item__image">
              <img
                :src="
                  bucketResults.find(x => x.id === chatMessage.userId)?.image ||
                    `/img/football-head.png`
                "
                alt=""
              />
            </div>

            <div class="chat-item">
              <div class="chat-item__author">
                <span v-if="user.id !== chatMessage.userId">
                  <router-link :to="`/user/${chatMessage.userId}`">
                    {{ bucketResults.find(x => x.id === chatMessage.userId)?.name || "Navn Ukjent" }}
                  </router-link>
                  &nbsp;
                </span>
                <time class="text-xsmall"
                  >{{
                    chatMessage.created.toLocaleString("no", {
                      day: "numeric",
                      month: "numeric"
                    })
                  }}
                  -
                  <em>{{
                    chatMessage.created.toLocaleString("no", {
                      hour: "numeric",
                      minute: "2-digit"
                    })
                  }}</em>
                </time>
              </div>

              <p class="chat-item__message">{{ chatMessage.text }}</p>
            </div>
          </div>
        </div>

        <form class="chat-form" @submit.prevent="submitChatMessage">
          <div class="form-row">
            <textarea
              class="input"
              v-model="newChatMessage"
              maxlength="160"
              @keydown.enter.exact.prevent
              @keyup.enter.exact="submitChatMessage"
              @keydown.enter.shift.exact="addNewLineToChatMsg"
            />
            <label class="label">Skriv melding</label>
            <p
              class="chat-form__counter"
              aria-hidden="true"
              :class="{
                alert: 160 - newChatMessage.length < 15,
                'the-end': 160 - newChatMessage.length < 1
              }"
            >
              {{ 160 - newChatMessage.length }}
            </p>
            <button type="submit" class="btn" ref="submitMsgBtn" id="submitMsgBtn" :click="submitChatMessage">Send</button>
          </div>
        </form>
      </div>
    </div>
  </div> -->
</template>

<!-- <script lang="ts">
import { computed, defineComponent, ref, onMounted } from "vue";
import TabMenu from "@/features/Navigation/TabMenu.vue";
import { ChatMessage } from "../../../shared/models/ChatMessage";
import { shortUuid } from "../../../shared/utils/uuid";
import userStore from "../store/userStore";
import contextStore from "../store/contextStore";
import teamStore from "../store/teamStore";
import gameStore from "../store/gameStore";
import couponStore from "../store/couponStore";
import scoreStore from "../store/scoreStore";

export default defineComponent({
  setup() {
    const { isAuthenticated, user } = userStore();
    const { contextId } = contextStore();
    const { teams, currentTeam, lastSelectedTeamId, saveCurrentTeam } = teamStore();
    const { currentGame } = gameStore();
    const { currentCoupon } = couponStore();
    const { bucketResults } = scoreStore();

    let initialPositioning = true;
    let fetchOldTriggered = false;

    const newChatMessage = ref("");
    const take = ref(15);
    const visibleChatMessages = computed(() => {
      if (!currentTeam.value) return [];

      const chatMessages = currentTeam.value.chatMessages;

      if (fetchOldTriggered) {
        fetchOldTriggered = false;
        // TODO: set scroll position appropriately
      } else {
        initialPositioning = true;
      }

      return chatMessages
        .sort((a, b) => b.created.getTime() - a.created.getTime())
        .slice(0, take.value)
        .reverse();
    });

    const submitChatMessage = async () => {
      const thisBtn = document.getElementById('submitMsgBtn');
      const text = newChatMessage.value.replace(/\s+/g, " ").trim();
      if (!text) return;

      const chatMessage = new ChatMessage({
        id: shortUuid(20),
        userId: user.value.id,
        text
      });

      if (!currentTeam.value) return;

      currentTeam.value.chatMessages = [...currentTeam.value.chatMessages, chatMessage];

      await saveCurrentTeam();

      newChatMessage.value = "";
      // set focus to button, to lower the textarea
      if (thisBtn !== null) {
        thisBtn.focus();
      }
    };

    const fetchOlderMessages = (msgCount:number) => {
      fetchOldTriggered = true;
      take.value = msgCount;
    };

    const addNewLineToChatMsg = () => {
      console.debug("addNewLineToChatMsg", newChatMessage.value);
    };

    const jumpToLatestMessage = () => {
      if (initialPositioning) {
        setTimeout(function() {
          const msgList = document.getElementById("chatMessageList");
          if (msgList !== null) {
            msgList.scrollTop = msgList.scrollHeight;
          }
          initialPositioning = false;
          console.log("jumped to latest. Initial positioning:", initialPositioning);
        }, 50);
      }
    };

    return {
      take,
      visibleChatMessages,
      submitChatMessage,
      addNewLineToChatMsg,
      newChatMessage,
      isAuthenticated,
      user,
      contextId,
      teams,
      lastSelectedTeamId,
      currentTeam,
      currentGame,
      currentCoupon,
      bucketResults,
      jumpToLatestMessage,
      fetchOlderMessages,
      initialPositioning
    };
  },
  updated() {
    this.jumpToLatestMessage();
  },
  components: { TabMenu }
});
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/chat";
</style>
