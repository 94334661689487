import { defineComponent, watch, ref } from "vue";
import router from "./router";
import userStore from "./store/userStore";
import SideMenu from "@/features/Navigation/SideMenu.vue";
import BottomMenu from "@/features/Navigation/BottomMenu.vue";
export default defineComponent({
    setup: function () {
        var isAuthenticated = userStore().isAuthenticated;
        var isSideMenuOpen = ref(false);
        watch(function () { return router.currentRoute.value.path; }, function (path, prevPath) {
            isSideMenuOpen.value = false;
        });
        return {
            isAuthenticated: isAuthenticated,
            isSideMenuOpen: isSideMenuOpen,
        };
    },
    components: {
        SideMenu: SideMenu,
        BottomMenu: BottomMenu,
    },
});
