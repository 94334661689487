<template>
  <svg class="modal-icon modal-icon--success" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
       viewBox="0 0 24 24">
    <path
          d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z" />
  </svg>
</template>

<style lang="scss" scoped>
@import "./src/SASS/settings";

.modal-icon {
  width: 80px;
  height: 80px;
  position: relative;
  margin-top: -40px;
  margin-bottom: -$space;
  background-color: white;
  border: 5px solid white;
  border-radius: 50%;

  &--success {
    fill: $color-success;
  }

  &--error {
    fill: $color-wrong;
  }
}
</style>
