export var isDev = false;
// Norsk Tipping URLs
/** ex. https://services.norsk-tipping.no/getresults/service/results/tippingS */
export var norskTippingGameUrl = function (tense, key) {
    return ("https://services.norsk-tipping.no/getresults/service/" + tense + "/tipping" + key).toLowerCase();
};
// Game types
export var SATURDAY = { id: 8, key: "L", name: "saturday", displayName: "Lørdag" };
export var SUNDAY = { id: 9, key: "S", name: "sunday", displayName: "Søndag" };
export var MIDWEEK = { id: 10, key: "M", name: "midweek", displayName: "Midtuke" };
export var GAMETYPES = [SATURDAY, SUNDAY, MIDWEEK];
