import { defineComponent } from "vue";
export default defineComponent({
    props: {
        showModal: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        window.addEventListener("keyup", function (e) {
            if (e.key === "Escape") {
                emit("close");
            }
        });
    },
});
