// Translations for these potential errors:
// https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html#error-codes_3
// https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html#error-codes_12
var FirebaseErrorMessages = /** @class */ (function () {
    function FirebaseErrorMessages() {
        // Maybe we shouldn't expose emails are registered?
        this["auth/email-already-in-use"] = "Det finnes allerede en bruker registrert på denne epostadressen";
        this["auth/invalid-email"] = "Ugyldig epostadresse";
        this["auth/weak-password"] = "Passordet er for svakt";
        // This also sort of exposes if there is a user registered on this email
        this["auth/wrong-password"] = "Du har oppgitt feil passord";
        this["auth/too-many-requests"] = "Nå har du prøvd litt mange ganger. Prøv igjen om noen minutter.";
        this["auth/requires-recent-login"] = "Denne operasjonen er sensitiv og krever nylig innlogging. Vennligst logg inn på nytt og prøv igjen.";
        this.default = "Innloggingen mislyktes";
    }
    return FirebaseErrorMessages;
}());
var firebaseErrorMessages = new FirebaseErrorMessages();
var getFirebaseErrorMessage = function (error) {
    return (error && error.code && firebaseErrorMessages[error.code]) || firebaseErrorMessages.default;
};
export { firebaseErrorMessages, getFirebaseErrorMessage };
