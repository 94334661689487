import { ref, computed } from "vue";
var contextId = ref("");
var contextType = computed(function () {
    if (contextId.value.length == 0)
        return "NONE";
    if (contextId.value.length < 22)
        return "TEAM";
    else
        return "USER";
});
export default (function () {
    return { contextId: contextId, contextType: contextType };
});
