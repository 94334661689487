<template>
  <!-- <div v-if="!isEmailVerified" class="alert">
    <div v-if="!emailVerificationReSent">
      <p>Du har ikke bekreftet e-postadressen din og f&aring;r ikke levert kupongen f&oslash;r dette er gjort.</p>
      <p>Har du ikke mottatt e-post med lenke for &aring; verifisere din konto?<br><button class="btn btn--text-link" @click="resendValidationEmail()">Klikk her for &aring; f&aring; tilsendt ny e-post.</button></p>
    </div>

    <div v-else>
      <p>Sjekk e-posten din og følg instruksene for å verifisere kontoen din.</p>
    </div>
  </div> -->

  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>

  <!-- <div class="side-padding" v-if="currentGame">
    <div v-for="(usersTeam, index) in user.teamInfoList" :key="index">
      <div v-for="(team, index) in teams" :key="index">
        <p class="text-small" v-if="team.id === usersTeam.teamId && team.couponGameIds.indexOf(currentGame.id) < 0">
          <strong>{{ team.name }}</strong> har ikke levert kupongen for denne uken, tar du ansvar?
        </p>
      </div>
    </div>
  </div> -->

  <!-- <TabMenu v-if="isAuthenticated" :tabs="[
    {
      text: user.name,
      isActive: contextId === user.id,
      onClick: () => (contextId = user.id),
    },
    ...teams.map(team => {
      return {
        text: team.name,
        isActive: contextId === team.id,
        onClick: () => {
          contextId = team.id;
          lastSelectedTeamId = team.id;
        },
      };
    }),
  ]" /> -->
  <!-- <div class="tab-content tab-content--wide tab-content--coupon" v-if="currentGame">
    <div class="tab-content__header">
      <CouponCarousel />
      <CouponTips v-if="!currentGame.hasClosedSales && currentCoupon.lastSavedUserId === undefined" />
      <CouponStatus v-else-if="currentGame.hasClosedSales" :currentCoupon="currentCoupon" :currentGame="currentGame" />
    </div>

    <div class="coupon">
      <div class="coupon__collection" v-if="currentCoupon">
        <CouponLine v-for="(choice, i) in currentCoupon.choices" :key="`${i} ${currentCoupon.id}`" :choice="choice"
                    :event="currentGame.events[i]" @update:choice="(value) => updateChoice(i, value)" />
      </div>
    </div>

    <CouponDeliveryButton v-if="!currentGame.hasClosedSales && isEmailVerified" />
  </div> -->
</template>

<!-- <script lang="ts">
import { defineComponent, ref } from "vue"
import TabMenu from "@/features/Navigation/TabMenu.vue"
import CouponTips from "@/features/Coupon/CouponTips.vue"
import CouponStatus from "@/features/Coupon/CouponStatus.vue"
import CouponLine from "@/features/Coupon/CouponLine.vue"
import CouponCarousel from "@/features/Coupon/CouponCarousel.vue"
import CouponDeliveryButton from "@/features/Coupon/CouponDeliveryButton.vue"
import { fAuth } from "../../registerFirebase"
import userStore from "../../store/userStore"
import contextStore from "../../store/contextStore"
import teamStore from "../../store/teamStore"
import gameStore from "../../store/gameStore"
import couponStore from "../../store/couponStore"

export default defineComponent({
  setup() {
    const { isAuthenticated, isEmailVerified, user } = userStore()
    const { contextId } = contextStore()
    const { teams, lastSelectedTeamId } = teamStore()
    const { currentGame } = gameStore()
    const { currentCoupon, setCoupon } = couponStore()
    const emailVerificationReSent = ref(false)

    const updateChoice = (i: number, value: string) => {
      if (!currentCoupon.value) return
      currentCoupon.value.choices[i] = value
      setCoupon()
    }

    const resendValidationEmail = () => {
      if(fAuth.currentUser) {
        fAuth.currentUser.sendEmailVerification()
          .then(() => {
            emailVerificationReSent.value = true;
          });
      }
    }

    return {
      isAuthenticated,
      isEmailVerified,
      user,
      contextId,
      teams,
      lastSelectedTeamId,
      currentGame,
      currentCoupon,
      updateChoice,
      resendValidationEmail,
      emailVerificationReSent,
    }
  },
  components: {
    TabMenu,
    CouponCarousel,
    CouponTips,
    CouponStatus,
    CouponLine,
    CouponDeliveryButton,
  },
})
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/coupon";
</style>
