<template>
  <img src="../assets/logo.svg" alt="Laster..." />
</template>

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/mixins";

img {
  position: fixed;
  bottom: $main-menu-bar-height;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  animation: spin 2.5s linear infinite;

  @include iPhone-X {
    bottom: $main-menu-bar-height + $iPhone-X-safe-area;
  }
}

@keyframes spin {
  0% {
    left: calc(50vw - 10em);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    left: calc(50vw + 10em);
    opacity: 0;
    transform: rotate(550deg);
  }
}
</style>
