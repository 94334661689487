import { __assign, __extends } from "tslib";
import { DeliveryCoupon } from "./DeliveryCoupon";
import { UserTeamInfo } from "./UserTeamInfo";
import { VisualThing } from "./VisualThing";
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User(init) {
        var _this = _super.call(this, init) || this;
        _this.coupons = init.coupons ? init.coupons.map(function (x) { return new DeliveryCoupon(x); }) : [];
        _this.couponGameIds = _this.coupons.map(function (x) { return x.gameId; });
        _this.teamInfoList = init.teamInfoList ? init.teamInfoList.map(function (x) { return new UserTeamInfo(x); }) : [];
        _this.hasCoupons = _this.coupons.length > 0;
        _this.hasTeams = _this.teamInfoList.length > 0;
        _this.favoriteTeam = init.favoriteTeam || "";
        _this.position = init.position || "";
        _this.description = init.description || "";
        return _this;
    }
    User.prototype.toDynamic = function () {
        return __assign(__assign({}, this), { coupons: this.coupons.map(function (x) { return x.toDynamic(); }), teamInfoList: this.teamInfoList.map(function (x) { return x.toDynamic(); }) });
    };
    return User;
}(VisualThing));
export { User };
