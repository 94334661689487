import { __assign, __extends } from "tslib";
import { normalizeDate } from "../utils/dateHelper";
import { getDeliveryCouponId } from "../utils/namingHelper";
import { Thing } from "./Thing";
var DeliveryCoupon = /** @class */ (function (_super) {
    __extends(DeliveryCoupon, _super);
    function DeliveryCoupon(init) {
        var _this = _super.call(this, init) || this;
        _this.ownerId = init.ownerId;
        _this.lastSavedUserId = init.lastSavedUserId;
        _this.saved = normalizeDate(init.saved);
        _this.gameId = init.gameId || 0;
        _this.choices = init.choices || Array.from({ length: 12 }, function () { return ""; });
        _this.isTeamCoupon = !!_this.ownerId && _this.ownerId.length < 22;
        if (init.gameId && init.ownerId)
            _this.id = getDeliveryCouponId(init.gameId, init.ownerId);
        return _this;
    }
    DeliveryCoupon.prototype.toDynamic = function () {
        return __assign({}, this);
    };
    return DeliveryCoupon;
}(Thing));
export { DeliveryCoupon };
