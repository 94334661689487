import { __awaiter, __generator } from "tslib";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/performance";
import { firebaseConfig } from "../../tippelaget.firebase.config";
firebase.initializeApp(firebaseConfig);
/** https://firebase.google.com/docs/firestore/manage-data/enable-offline#configure_offline_persistence */
firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
    if (err.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled in one tab at a a time
    }
    else if (err.code === "unimplemented") {
        // The current browser does not support all of the features required to enable persistence
    }
});
// Subsequent queries will use persistence, if it was enabled successfully
export var fAuth = firebase.auth();
export var fDb = firebase.firestore();
export var fAnalytics = firebase.analytics();
export var fPerformance = firebase.performance();
/** Get a single doc from cache if cached, else server. The cached doc is never updated, as onSnapshot does */
export var getDocCacheFirst = function (doc) { return __awaiter(void 0, void 0, void 0, function () {
    var docSnapshot, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4 /*yield*/, doc.get({ source: "cache" })];
            case 1:
                docSnapshot = _a.sent();
                if (!docSnapshot.exists)
                    throw new Error("cache is empty for doc");
                return [2 /*return*/, docSnapshot];
            case 2:
                error_1 = _a.sent();
                return [4 /*yield*/, doc.get({ source: "server" })];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
/** Get multiple docs from cache if cached, else server. The cached doc is never updated, as onSnapshot does */
export var getDocsCacheFirst = function (query) { return __awaiter(void 0, void 0, void 0, function () {
    var querySnapshot, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 4]);
                return [4 /*yield*/, query.get({ source: "cache" })];
            case 1:
                querySnapshot = _a.sent();
                if (querySnapshot.empty)
                    throw new Error("cache is empty for doc");
                return [2 /*return*/, querySnapshot];
            case 2:
                error_2 = _a.sent();
                return [4 /*yield*/, query.get({ source: "server" })];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
