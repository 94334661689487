<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>
  <!-- <div class="intro-wrapper">
    <WelcomeMessage />
    <form class="intro-form" @submit.prevent="login">
      <h1>Logg inn</h1>
      <div class="form-row">
        <label class="label" for="email">E-post</label>
        <input
          class="input"
          id="email"
          type="email"
          required
          v-model="email"
          autocomplete="login username"
        />
      </div>
      <div class="form-row">
        <label class="label" for="password">Passord</label>
        <input
          class="input"
          id="password"
          type="password"
          required
          v-model="password"
          autocomplete="login current-password"
        />
      </div>
      <p v-if="errorMessage">{{ errorMessage }}</p>
      <div class="btn-wrapper">
        <button :disabled="isLoading" ref="signInButton" type="submit" class="btn">Logg inn</button>
      </div>
    </form>

    <p class="u-text-center">
      <router-link
        :to="
          $route.query.returnUrl
            ? `/forgot?returnUrl=${$route.query.returnUrl}`
            : '/forgot'
        "
      >Glemt passordet ditt?</router-link>
    </p>

    <p class="u-text-center">
      Ikke bruker ennå?
      <router-link
        :to="
          $route.query.returnUrl
            ? `/sign-up?returnUrl=${$route.query.returnUrl}`
            : '/sign-up'
        "
      >Registrer deg her</router-link>
    </p>
    <Spinner v-if="isLoading" />
  </div> -->
</template>

<!-- <script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { fAuth } from "../registerFirebase";
import { getFirebaseErrorMessage } from "../i18n/firebaseErrorMessages";
import WelcomeMessage from "./WelcomeMsg.vue";
import router from "../router";

export default defineComponent({
  setup() {
    const email = ref("");
    const password = ref("");
    const isLoading = ref(false);
    const errorMessage = ref("");

    const isLoginValid = computed(
      () => !!email.value && !!password.value && password.value.length > 5
    );

    const login = () => {
      if (!isLoginValid.value) {
        errorMessage.value = getFirebaseErrorMessage(null);
        return;
      }
      isLoading.value = true;

      const userCredential = fAuth
        .signInWithEmailAndPassword(email.value, password.value)
        .then((x) => {
          console.debug("user logged in", x);
          window.location.replace("/");
        })
        .catch(
          (firebaseError) =>
            (errorMessage.value = getFirebaseErrorMessage(firebaseError))
        )
        .finally(() => {
          isLoading.value = false;
        });
    };

    return {
      email,
      password,
      login,
      isLoading,
      errorMessage
    };
  },
  components: {
    WelcomeMessage
  }
});
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/intro";
</style>
