import { __awaiter, __generator } from "tslib";
import { createApp } from "vue";
import App from "./App.vue";
import Spinner from "./atoms/Spinner.vue";
import Modal from "./atoms/Modal.vue";
import ModalIconError from "./atoms/ModalIconError.vue";
import ModalIconSuccess from "./atoms/ModalIconSuccess.vue";
import FriendlyDate from "./atoms/FriendlyDate.vue";
import { fAuth, fPerformance } from "./registerFirebase";
import "./registerServiceWorker";
import router from "./router";
import contextStore from "./store/contextStore";
fAuth.onAuthStateChanged(function (firebaseUser) { return __awaiter(void 0, void 0, void 0, function () {
    var contextId;
    return __generator(this, function (_a) {
        contextId = contextStore().contextId;
        contextId.value = "" + (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.uid);
        startApp();
        return [2 /*return*/];
    });
}); });
var startApp = function () {
    var trace = fPerformance.trace("app initialization");
    trace.start();
    createApp(App)
        .use(router)
        .component("Spinner", Spinner)
        .component("Modal", Modal)
        .component("ModalIconError", ModalIconError)
        .component("ModalIconSuccess", ModalIconSuccess)
        .component("FriendlyDate", FriendlyDate)
        .mount("#app");
    trace.stop();
    console.debug("app initialized");
};
