import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { computed, onMounted, ref } from "vue";
import { fAuth, fDb, getDocCacheFirst, getDocsCacheFirst } from "../registerFirebase";
import { Team } from "../../../shared/models/Team";
var isMounted = false;
var teams = ref([]);
var lastSelectedTeamId = ref("");
var getQueryByUserId = function (uid) { return fDb.collection("teams").where("memberIds", "array-contains", uid); };
var getQueryByTeamId = function (teamId) { return fDb.doc("teams/" + teamId); };
var fetchTeams = function () {
    isMounted = true;
    var firebaseUser = fAuth.currentUser;
    if (!firebaseUser) {
        console.warn("fetch teams failed, firebaseUser null");
        return;
    }
    var query = getQueryByUserId(firebaseUser.uid);
    query.onSnapshot(function (snapshot) {
        var teamClones = __spreadArray([], teams.value);
        var cacheCount = 0;
        var serverCount = 0;
        snapshot.docChanges().forEach(function (change) {
            change.doc.metadata.fromCache ? cacheCount++ : serverCount++;
            var team = new Team(__assign(__assign({}, change.doc.data()), { id: change.doc.id }));
            switch (change.type) {
                case "added":
                    teamClones.push(team);
                    break;
                case "modified":
                    teamClones = teamClones.filter(function (x) { return x.id !== team.id; });
                    teamClones.push(team);
                    break;
                case "removed":
                    teamClones = teamClones.filter(function (x) { return x.id !== team.id; });
                    break;
            }
        });
        if (teamClones.length > 0) {
            if (teams.value.length === 0) {
                lastSelectedTeamId.value = teamClones[0].id;
            }
            teams.value = teamClones;
        }
        console.debug("fetchTeams snapshot finished", cacheCount, "from cache", serverCount, "from server");
    }, function (error) {
        console.debug("fetchTeams snapshot error", error);
    });
};
var getTeam = function (id, cacheFirst) {
    if (cacheFirst === void 0) { cacheFirst = true; }
    return __awaiter(void 0, void 0, void 0, function () {
        var query, doc, _a, team;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    query = getQueryByTeamId(id);
                    if (!cacheFirst) return [3 /*break*/, 2];
                    return [4 /*yield*/, getDocCacheFirst(query)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, query.get()];
                case 3:
                    _a = _b.sent();
                    _b.label = 4;
                case 4:
                    doc = _a;
                    if (!doc.exists) {
                        console.debug("team not found", id, "from " + (doc.metadata.fromCache ? "cache" : "server"));
                        return [2 /*return*/];
                    }
                    team = new Team(__assign(__assign({}, doc.data()), { id: id }));
                    console.debug("team", team, "from " + (doc.metadata.fromCache ? "cache" : "server"));
                    return [2 /*return*/, team];
            }
        });
    });
};
var getTeams = function (uid) { return __awaiter(void 0, void 0, void 0, function () {
    var query, response, teams;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                query = getQueryByUserId(uid);
                return [4 /*yield*/, getDocsCacheFirst(query)];
            case 1:
                response = _a.sent();
                teams = [];
                response.forEach(function (doc) {
                    var team = new Team(__assign(__assign({}, doc.data()), { id: doc.id }));
                    teams.push(team);
                });
                return [2 /*return*/, teams];
        }
    });
}); };
var deleteTeam = function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                console.debug("deleting team", id);
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, getQueryByTeamId(id).delete()];
            case 2:
                _a.sent();
                console.debug("deleting team sucessful");
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                console.debug(error_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
var saveTeam = function (team) { return __awaiter(void 0, void 0, void 0, function () {
    var couponLength, latestGameId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!team)
                    return [2 /*return*/];
                if (team.couponGameIds.length < team.coupons.length) {
                    couponLength = team.coupons.length;
                    latestGameId = team.coupons[couponLength - 1].gameId;
                    team.couponGameIds.push(latestGameId);
                }
                return [4 /*yield*/, getQueryByTeamId(team.id).set(team.toDynamic())];
            case 1:
                _a.sent();
                console.debug("team saved", team.id);
                return [2 /*return*/];
        }
    });
}); };
var disconnectUserFromTeam = function (team, userId) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(team === null || team === void 0 ? void 0 : team.id))
                    return [2 /*return*/];
                if (!userId)
                    return [2 /*return*/];
                if (!team.memberIds.includes(userId)) {
                    console.debug("disconnect team failed, user not in team");
                    return [2 /*return*/];
                }
                team.memberIds = team.memberIds.filter(function (x) { return x !== userId; });
                if (!(team.memberIds.length === 0)) return [3 /*break*/, 2];
                console.debug("no members left, deleting team");
                return [4 /*yield*/, deleteTeam(team.id)];
            case 1:
                _a.sent();
                return [2 /*return*/];
            case 2: return [4 /*yield*/, saveTeam(team)];
            case 3:
                _a.sent();
                console.debug("disconnected team", team.id, "from user", userId);
                return [2 /*return*/];
        }
    });
}); };
export default (function () {
    if (!isMounted) {
        onMounted(fetchTeams);
    }
    var currentTeam = computed(function () {
        return teams.value.find(function (x) { return x.id === lastSelectedTeamId.value; });
    });
    var saveCurrentTeam = function () { return saveTeam(currentTeam.value); };
    return {
        lastSelectedTeamId: lastSelectedTeamId,
        teams: teams,
        currentTeam: currentTeam,
        saveTeam: saveTeam,
        saveCurrentTeam: saveCurrentTeam,
        getTeam: getTeam,
        getTeams: getTeams,
        disconnectUserFromTeam: disconnectUserFromTeam,
        deleteTeam: deleteTeam,
    };
});
