import { __extends } from "tslib";
import { Thing } from "./Thing";
var VisualThing = /** @class */ (function (_super) {
    __extends(VisualThing, _super);
    function VisualThing(init) {
        var _this = _super.call(this, init) || this;
        _this.name = init.name || "";
        _this.image = init.image || "";
        return _this;
    }
    return VisualThing;
}(Thing));
export { VisualThing };
