<template>
  <div class="side-padding" style="padding-top: 10vh; text-align: center;">
    <p>Tippelaget.no er på pause. Vi får se om vi kommer tilbake.</p>
  </div>
  <!-- <div v-if="!user">Fant ikke brukeren</div>
  <div v-else class="tab-content">
    <div class="profile">
      <ProfileImage :user="isEditMode ? updatedUser : user" />

      <div class="profile__content" v-if="isEditMode">
        <form class="intro-form" @submit.prevent="submitForm()">
          <div class="form-row">
            <label class="label" for="name">Navn</label>
            <input class="input" id="name" type="text" v-model="updatedUser.name" autocomplete="update name" />
          </div>

          <div class="form-row">
            <label class="label" for="image">Bildeadresse</label>
            <input
              class="input"
              id="image"
              type="text"
              v-model="updatedUser.image"
              autocomplete="update image"
              placeholder="https://example.com/image/me.jpg"
            />
          </div>

          <div class="form-row">
            <label class="label" for="favorite-team">Favorittlag</label>
            <input
              class="input"
              id="favorite-team"
              type="text"
              v-model="updatedUser.favoriteTeam"
              autocomplete="update favorite-team"
            />
          </div>

          <div class="form-row">
            <label class="label" for="description">Presentasjon</label>
            <textarea
              class="input"
              id="description"
              type="text"
              v-model="updatedUser.description"
              autocomplete="update description"
              placeholder="Skyter like høyt over med begge ben. Holder et stabilt lavt nivå."
            />
          </div>

          <template v-if="submitFormStatus.showMessage">
            <p v-if="isFormValid" :class="submitFormStatus.isSuccess ? 'form-success' : 'alert'">
              {{ submitFormStatus.message }}
            </p>
            <p v-else>{{ formErrors }}</p>
          </template>

          <div class="button-row">
            <button ref="signInButton" type="submit" class="btn">Lagre</button>
            &nbsp;
          </div>
          <div class="button-row">
            <button class="btn btn--text-link" type="button" @click="toggleEditMode">Avbryt - tilbake til profil</button>
            <button type="button" class="btn btn--text-link" @click.prevent="showDeleteModal = true">Slett min bruker</button>
          </div>
        </form>
      </div>

      <ProfileDetails
        v-else
        :user="user"
        :hasEditAccess="true"
        @toggle:edit="toggleEditMode"
        @toggle:change-password="toggleChangePasswordMode"
      />

      <ProfileChangePassword
        v-if="isChangePasswordMode"
        @resetPassword="resetPassword"
        @toggle:change-password="toggleChangePasswordMode"
      />

      <ProfileTeams :teams="teams" :user="user" :hasEditAccess="true" />
    </div>
  </div>

  <Modal :showModal="showDeleteModal" @close="showDeleteModal = false">
    <div class="modal-content">
      <ModalIconError />

      <template v-if="isRecentLoginRequired">
        <h2 class="modal-title">Denne operasjonen krever at du er logget inn nylig.</h2>
        <div class="modal-btn-wrapper">
          <router-link class="btn btn--dark" type="button" to="/login">Logg inn på nytt nå</router-link>
          <button class="btn btn--secondary" type="button" @click="showDeleteModal = false">Avbryt</button>
        </div>
      </template>
      <template v-else>
        <h2 class="modal-title">Er du sikker på at du vil slette brukeren din?</h2>
        <div class="modal-btn-wrapper">
          <button class="btn btn--dark" type="button" @click="deleteAndDiscUser">Ja, slett brukeren min nå</button>
          <button class="btn btn--secondary" type="button" @click="showDeleteModal = false">Nei, avbryt</button>
        </div>
      </template>
    </div>
  </Modal> -->
</template>

<!-- <script lang="ts">
import { defineComponent, ref } from "vue";
import { sleep } from "../../../../shared/utils/sleep";
import ProfileImage from "./ProfileImage.vue";
import ProfileDetails from "./ProfileDetails.vue";
import ProfileChangePassword from "./ProfileChangePassword.vue";
import ProfileTeams from "./ProfileTeams.vue";
import userStore from "../../store/userStore";
import contextStore from "../../store/contextStore";
import teamStore from "../../store/teamStore";
import gameStore from "../../store/gameStore";
import couponStore from "../../store/couponStore";
import { fAuth, fAnalytics } from "../../registerFirebase";
import { User } from "../../../../shared/models/User";

export default defineComponent({
  setup() {
    const { isAuthenticated, user, saveCurrentUser, deleteUser } = userStore();
    const { contextId } = contextStore();
    const { teams, lastSelectedTeamId, disconnectUserFromTeam } = teamStore();
    const { currentGame } = gameStore();
    const { currentCoupon, setCoupon } = couponStore();

    const showDeleteModal = ref(false);
    const isRecentLoginRequired = ref(false);
    const formErrors = ref({});
    const isFormValid = ref(false);
    const submitFormStatus = ref({
      isSuccess: false,
      showMessage: false,
      message: ""
    });

    const isEditMode = ref(false);
    const updatedUser = ref({} as User);
    const toggleEditMode = () => {
      updatedUser.value = new User({ ...user.value });
      isEditMode.value = !isEditMode.value;
    };

    const isChangePasswordMode = ref(false);
    const toggleChangePasswordMode = () => {
      isChangePasswordMode.value = !isChangePasswordMode.value;
    };

    const submitForm = async () => {
      console.debug("save", updatedUser.value);

      user.value = updatedUser.value;
      await saveCurrentUser();

      submitFormStatus.value = {
        showMessage: true,
        isSuccess: true,
        message: "Brukeren ble oppdatert"
      };
      isFormValid.value = true;
      await sleep(2000);
      submitFormStatus.value = {
        showMessage: true,
        isSuccess: true,
        message: "Går tilbake til profil.."
      };
      await sleep(2000);
      submitFormStatus.value.showMessage = false;
      isEditMode.value = false;
    };
    const deleteAndDiscUser = async () => {
      if (!fAuth.currentUser) return;

      // trigger reauth if needed
      try {
        await fAuth.currentUser.updateEmail("reauth" + fAuth.currentUser.email);
      } catch (error) {
        console.debug("asd", error);
        if (error.code === "auth/requires-recent-login") {
          isRecentLoginRequired.value = true;
          return;
        }
      }

      // disc from all teams
      for (const team of teams.value) {
        await disconnectUserFromTeam(team, user.value.id);
      }

      // delete db user
      await deleteUser(user.value.id);

      // delete auth user
      try {
        await fAuth.currentUser.delete();
        console.debug("deleted auth user", fAuth.currentUser.uid);
      } catch (error) {
        console.debug("delete auth user error", error);
      }

      fAnalytics.logEvent("user_deleted", { userId: user.value.id });

      // "router push" without db permission errors
      window.location.replace("/login");
    };

    return {
      showDeleteModal,
      isRecentLoginRequired,
      formErrors,
      isFormValid,
      submitFormStatus,
      isEditMode,
      updatedUser,
      toggleEditMode,
      submitForm,
      deleteAndDiscUser,
      isAuthenticated,
      user,
      contextId,
      teams,
      lastSelectedTeamId,
      currentGame,
      currentCoupon,
      isChangePasswordMode,
      toggleChangePasswordMode
    };
  },
  components: {
    ProfileImage,
    ProfileDetails,
    ProfileTeams,
    ProfileChangePassword
  }
});
</script> -->

<style lang="scss" scoped>
@import "./src/SASS/settings";
@import "./src/SASS/modules/profile";
@import "./src/SASS/atoms/lists";

.form-success {
  background-color: rgb(25, 148, 0);
  color: white;
  padding: 0.5em;
}

#description {
  height: 100px;
}
</style>
