import { defineComponent, computed } from "vue";
import teamStore from "../../store/teamStore";
export default defineComponent({
    setup: function () {
        var teams = teamStore().teams;
        var hasTeam = computed(function () { return teams.value.length > 0; });
        var showChatNotifications = false;
        return { hasTeam: hasTeam, showChatNotifications: showChatNotifications };
    },
});
