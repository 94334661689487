import { normalizeDate } from "../utils/dateHelper";
/** Inspired by https://schema.org/Thing */
var Thing = /** @class */ (function () {
    function Thing(init) {
        this.id = init.id || "";
        this.created = normalizeDate(init.created);
    }
    return Thing;
}());
export { Thing };
