export var normalizeDate = function (dateObject) {
    var type = typeof dateObject;
    switch (type) {
        case "string":
            return new Date(dateObject);
        case "object": // Date or Firebase.Timestamp
            return dateObject instanceof Date ? dateObject : dateObject.toDate();
        default:
            return new Date();
    }
};
export var fromUtcPlusOneToUtc = function (utcPlusOneDate) { return addHours(utcPlusOneDate, -1); };
export var addHours = function (date, hours) {
    date.setHours(date.getHours() + hours);
    return date;
};
export var addDays = function (date, days) {
    date.setDate(date.getDate() + days);
    return date;
};
export var isDate = function (date) { return date instanceof Date && !isNaN(date.valueOf()); };
export var getHumanReadableTimeSince = function (past) {
    if (!isDate(past))
        return "";
    var timeBetweenNowAndPast = new Date().valueOf() - past.valueOf();
    var seconds = timeBetweenNowAndPast / 1000;
    if (seconds < 60)
        return Math.floor(seconds) + " sekunder siden";
    var minutes = seconds / 60;
    if (minutes < 30)
        return Math.floor(minutes) + " minutter siden";
    if (minutes < 60)
        return "en halvtime siden";
    var hours = minutes / 60;
    if (hours < 12)
        return Math.floor(hours) + " timer siden";
    if (hours < 24)
        return "i går";
    var days = hours / 24;
    if (days < 2)
        return Math.floor(days) + " dag siden";
    if (days < 7)
        return Math.floor(days) + " dager siden";
    var weeks = days / 7;
    if (weeks < 2)
        return Math.floor(weeks) + " uke siden";
    if (weeks < 52)
        return Math.floor(weeks) + " uker siden";
    return "over et år siden";
};
