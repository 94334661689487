import { __assign } from "tslib";
/** Holds information about a teams chat, regarding new message count */
var UserTeamInfo = /** @class */ (function () {
    function UserTeamInfo(init) {
        this.teamId = init.teamId;
        this.lastSeen = init.lastSeen || new Date();
    }
    UserTeamInfo.prototype.toDynamic = function () {
        return __assign({}, this);
    };
    return UserTeamInfo;
}());
export { UserTeamInfo };
